import * as React from "react"

import Seo from "../components/seo"
import { useStartPage } from "../hooks/useStartPage"
import StandardPage from "../components/standardPage"

const IndexPage = () => {
  const { hero, cta, content } = useStartPage()

  return <StandardPage hero={hero} cta={cta} content={content} />
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => {
  const { seo } = useStartPage()
  return <Seo title="Home" seo={seo} />
}

export default IndexPage
